import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'

import Container from '../components/Container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import H1 from '../components/H1'
import Hr from '../components/Hr'
import Layout from '../components/layout'

const Box = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px #e6e6e6, 0 1px 2px #d9d9d9;
  padding: 24px;
`

const ImageWrapper = styled.div`
  margin-bottom: 35px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
`

const Image = styled.img`
  height: auto;
  width: 100%;
`

const Article = ({ data }) => (
  <Layout>
    <Helmet>
      <title>{data.graphcms.article.title} - CSU Ortsverband Kastl</title>
    </Helmet>
    <Header pathname='/' />
      <Container>
      <Box>
        {
          data.graphcms.article.image && (
            <ImageWrapper><Image src={data.graphcms.article.image.url} /></ImageWrapper>
          )
        }
        <H1>{data.graphcms.article.title}</H1>
        <div css={{ lineHeight: 2 }} dangerouslySetInnerHTML={{ __html: data.graphcms.article.content.html }} />
      </Box>
      <Hr />
      <Link to='/'>zurück</Link>
    </Container>
    <Footer />
  </Layout>
)

export default Article

export const pageQuery = graphql`
  query GET_ARTICLE($id: ID!) {
    graphcms {
      article(where: { id: $id }, stage: PUBLISHED) {
        title
        image {
          url(transformation: { image: { resize: { width: 1200 } } })
        }
        content {
          html
        }
      }
    }
  }
`