import styled from 'styled-components'

export default styled.hr`
  border-bottom: 1px solid ${props => props.theme.border};
  border-left: none;
  border-right: none;
  border-top: none;
  height: 0;
  margin: 40px 0;
  padding: 0;
`